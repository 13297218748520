<template>
  <div class="team">
    <ul class="user_data">
      <li>
        <h2>团队人数</h2>
        <p>{{ statistics.total_inviter || 0 }}</p>
      </li>
      <li>
        <h2>推广人数</h2>
        <p>{{ statistics.total_vip_inviter || 0 }}</p>
      </li>
      <li>
        <h2>团队订单</h2>
        <p>{{ statistics.total_order || 0 }}</p>
      </li>
      <li>
        <h2>推广佣金</h2>
        <p>{{ statistics.total_amount || 0 }}</p>
      </li>
    </ul>

    <div class="tabs">
      <div :class="value1 == 0 ? 'act' : ''" @click="changeValue(0)">
        累计收益
      </div>
      <div @click="changeValue(1)" :class="value1 == 1 ? 'act' : ''">
        最新加入
      </div>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <ul class="team-ul">
        <li v-for="(item, index) in teamList" :key="item.member_id">
          <img class="tx" :src="item.member_avatar" alt="" />
          <div class="left">
            <b>{{ item.member_nickname }}</b>
            <span>已购买{{ item.total || 0 }}单</span>
          </div>
          <div class="right">
            <b>￥{{ item.total_money }}</b>
            <span>贡献收益</span>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import { List, DropdownMenu, DropdownItem } from "vant";
import { post } from "@get/http";
export default {
  components: {
    vanList: List,
    vanDropdownMenu: DropdownMenu,
    vanDropdownItem: DropdownItem,
  },
  data() {
    return {
      value1: 0,

      teamList: [],
      loading: false,
      finished: false,
      page: 1,
      statistics: {},
    };
  },
  watch: {
    value1() {},
  },
  methods: {
    changeValue(value) {
      this.page = 1;
      this.value1 = value;
      this.teamList = [];
      this.onLoad();
    },
    async onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      let data = await post("/lw.MemberOrderInviter/teamEarnings", {
        data: {
          page: this.page,
          pageSize: 20,
          isRegTimeDesc: this.value1,
        },
      });
      this.loading = false;
      this.page++;
      this.teamList.push(...data.list);
      this.statistics = data.statistics;
      // 加载状态结束
      if (data.list && data.list.length != 20) {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.user_data {
  display: flex;
  margin: rem(30) auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 rem(26);
  box-sizing: border-box;
  background: #ffffff;
  width: rem(690);
  height: rem(142);
  box-shadow: 0px rem(4) rem(10) rgba(0, 0, 0, 0.09);
  border-radius: rem(16);
  li {
    h2 {
      color: #666666;
      font-size: rem(24);
    }
    p {
      margin-top: rem(10);
      text-align: center;
      font-size: rem(30);
      color: #222222;
    }
  }
}

.team {
  background: #f8f8f8;
}
.team-ul {
  padding: rem(24);

  li {
    display: flex;
    box-sizing: border-box;
    padding: rem(24);
    align-items: center;
    margin-bottom: rem(24);
    border-radius: rem(16);
    background: #ffffff;
    .tx {
      width: rem(88);
      height: rem(88);
      border-radius: 50%;
    }
    .left {
      flex: 1;
    }

    div {
      margin-left: rem(16);
      b {
        display: block;
        margin-bottom: rem(10);
        font-size: rem(28);
      }
      span {
        display: block;
        font-size: rem(22);
        color: #989898;
      }
    }
  }
}

.tabs {
  display: flex;
  color: #989898;
  font-size: rem(30);
  padding: 0 rem(30);
  .act {
    color: #222;
    font-weight: bold;
  }
  div {
    margin-right: rem(30);
  }
}
</style>
